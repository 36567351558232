import { FIELDS_KEYS } from '_constants/forms';
import { validateEmail, validatePhone } from '_helpers/forms/validators';

const RULES = {
  [FIELDS_KEYS.firstName]: () => {
    return {
      required: 'Please provide First Name',
      pattern: {
        value: /^(?!\s*$)[ a-zA-Z\\&-]+$/i,
        message: 'Firs Name should contain only letters and & \\ - symbols',
      },
      maxLength: {
        value: 30,
        message: 'First Name should be less or equal 30 symbols',
      },
    };
  },
  [FIELDS_KEYS.lastName]: () => {
    return {
      required: 'Please provide Last Name',
      pattern: {
        value: /^(?!\s*$)[ a-zA-Z\\&-]+$/i,
        message: 'Last Name should contain only letters and & \\ - symbols',
      },
      maxLength: {
        value: 30,
        message: 'Last Name should be less or equal 30 symbols',
      },
    };
  },
  [FIELDS_KEYS.fullName]: () => {
    return {
      required: 'Please provide Full Name',
      pattern: {
        value: /^[ a-zA-Z\\&-]+$/i,
        message: 'Full Name should contain only letters and & \\ - symbols',
      },
      maxLength: {
        value: 90,
        message: 'Full Name should be less or equal 90 symbols',
      },
    };
  },
  [FIELDS_KEYS.password]: () => {
    return {
      required: 'Please enter Password',
      minLength: {
        value: 8,
        message: 'Password should be more or equal 8 symbols',
      },
      maxLength: {
        value: 128,
        message: 'Password should be less or equal 128 symbols',
      },
    };
  },
  [FIELDS_KEYS.email]: (options) => {
    return {
      required: 'Please provide Email',
      pattern: {
        value:
          /^(?!.*(?:''|\.\.))([a-zA-Z0-9_\-\.]*[a-zA-Z0-9_\-])@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i,
        message: 'Email address is not valid',
      },
      validate: validateEmail(options),
    };
  },
  [FIELDS_KEYS.phone]: (options) => {
    return {
      required: 'Please provide Phone number',
      validate: validatePhone(options),
    };
  },
  [FIELDS_KEYS.subject]: () => {
    return {
      required: 'Please provide subject',
    };
  },
  [FIELDS_KEYS.message]: () => {
    return {
      required: 'Please provide message',
      minLength: {
        value: 5,
        message: 'Message length must be between 5 and 2147483647',
      },
      maxLength: {
        value: 2147483647,
        message: 'Message length must be between 5 and 2147483647',
      },
    };
  },
  [FIELDS_KEYS.agreeWithTerms]: () => {
    return {
      required: 'You should accept the terms to be able to proceed',
    };
  },
};

export const getValidationRules = (fieldKey, options = {}) => {
  const getFieldRule = RULES[fieldKey];
  return getFieldRule ? getFieldRule(options) : undefined;
};
