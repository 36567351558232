export const FIELDS_KEYS = {
  firstName: 'firstName',
  lastName: 'lastName',
  fullName: 'fullName',
  email: 'email',
  phone: 'phone',
  password: 'password',
  subject: 'subject',
  message: 'message',
  agreeWithTerms: 'agreeWithTerms',
};
