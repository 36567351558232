import { FIELDS_KEYS } from '_constants/forms';
import { getValidationRules } from '_helpers/forms/rules';

const fieldProps = {
  [FIELDS_KEYS.firstName]: {
    name: FIELDS_KEYS.firstName,
    label: 'First Name',
    placeholder: 'First Name',
    required: true,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FIELDS_KEYS.lastName]: {
    name: FIELDS_KEYS.lastName,
    label: 'Last Name',
    placeholder: 'Last Name',
    required: true,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FIELDS_KEYS.fullName]: {
    name: FIELDS_KEYS.fullName,
    label: 'Full Name',
    placeholder: 'Full Name',
    required: true,
    onKeyPress: (event) => {
      if (!/[ a-zA-Z&\\-]/.test(event.key)) event.preventDefault();
    },
  },
  [FIELDS_KEYS.password]: {
    name: FIELDS_KEYS.password,
    label: 'Password',
    placeholder: 'you@company.com',
    required: true,
    type: 'password',
  },
  [FIELDS_KEYS.email]: {
    name: FIELDS_KEYS.email,
    label: 'Email',
    placeholder: 'you@company.com',
    required: true,
    type: 'email',
  },
  [FIELDS_KEYS.phone]: {
    name: FIELDS_KEYS.phone,
    label: 'Phone number',
    required: true,
    type: 'phone',
    mask: '(000) 000-0000',
    placeholder: '(123) 456-7890',
  },
  [FIELDS_KEYS.subject]: {
    name: FIELDS_KEYS.subject,
    label: 'Subject',
    placeholder: 'Subject',
    required: true,
  },
  [FIELDS_KEYS.message]: {
    name: FIELDS_KEYS.message,
    label: 'Message',
    placeholder: 'Leave us a message...',
    required: true,
  },
  [FIELDS_KEYS.agreeWithTerms]: {
    name: FIELDS_KEYS.agreeWithTerms,
    label: 'You agree to our friendly privacy policy.',
  },
};

export const getFieldProps = (fieldKey, { validationOptions } = {}) => {
  const props = fieldProps[fieldKey];
  return props
    ? { ...props, rules: getValidationRules(fieldKey, validationOptions) }
    : {};
};
