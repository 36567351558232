import PropTypes from 'prop-types';
import { useCallback, useMemo, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { OverlayTrigger, Tooltip, Form, Spinner } from 'react-bootstrap';

import { theme } from '_constants/theme';
import { ICONS_ALIASES } from '_constants/icons';
import { hexToRGBA } from '_helpers/theme';

import Icon from '_components/atomic/Icon';

const { colors, fontSizes, spaces } = theme;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledGroup = styled(forwardRef(({ labeled, noBottomSpace, ...props }, ref) => <Form.Group ref={ref} {...props} />))`
  position: relative;
  padding-top: ${({ labeled }) => (labeled ? spaces.largeX : 0)}px;
  margin-bottom: ${spaces.regular}px;

  ${({ noBottomSpace }) =>
    noBottomSpace &&
    css`
      margin-bottom: 0;
    `}
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledLabel = styled(forwardRef(({ textColor, invalid, ...props }, ref) => <Form.Label ref={ref} {...props} />))`
  position: absolute;
  top: 0;
  left: ${spaces.smallest}px;
  margin: 0;
  font-family: 'Inter';
  font-size: ${fontSizes.regular}px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.gray[700]};

  span {
    color: ${colors.red.primary};
  }

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};

      span {
        color: ${textColor};
      }
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      color: ${colors.red.primary};
    `}
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledControl = styled(forwardRef(({ textColor, placeholderColor, borderColor, backgroundColor, processing, ...props }, ref) => <Form.Control ref={ref} {...props} />))`
  /* min-height: 52px; */

  padding-top: ${spaces.small - 1}px;
  padding-bottom: ${spaces.small - 1}px;
  padding-left: ${spaces.regular}px;
  padding-right: ${spaces.regular}px;

  border: 1px solid ${colors.gray[300]};
  border-radius: 8px;
  background-color: ${colors.white};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: 'Inter';
  font-size: ${fontSizes.regular}px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.gray[700]};
  caret-color: ${colors.indigo.primary};

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
      caret-color: ${textColor};
    `}

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  ${({ borderColor }) =>
    borderColor &&
    css`
      border-color: ${borderColor};
    `}

  &::placeholder {
    color: ${colors.gray.placeholder};

    ${({ placeholderColor }) =>
      placeholderColor &&
      css`
        color: ${placeholderColor};
      `}
  }

  &:focus {
    border-color: ${colors.indigo.primary};
    box-shadow: none;

    ${({ textColor }) =>
      textColor &&
      css`
        border-color: ${textColor};
        color: ${textColor};
        caret-color: ${textColor};
      `}

    ${({ backgroundColor }) =>
      backgroundColor &&
      css`
        background-color: ${backgroundColor};
      `}
  }

  &:disabled {
    background-color: ${hexToRGBA(colors.gray.secondary, 0.4)};
  }

  &.form-control.is-valid {
    padding-right: ${spaces.regular}px;
    border-color: ${colors.gray[300]};
    background-image: none;

    &:focus {
      border-color: ${colors.indigo.primary};
      box-shadow: none;
    }
  }

  &.form-control.is-invalid {
    padding-right: ${spaces.regular}px;
    border-color: ${colors.red.primary};
    background-image: none;

    &:focus {
      border-color: ${colors.indigo.primary};
      box-shadow: none;
    }
  }

  ${({ processing }) =>
    processing &&
    css`
      padding-right: ${spaces.large6X}px;
    `};

  &::-webkit-file-upload-button {
    display: none;
  }

  &::-webkit-autofill,
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }
`;

// eslint-disable-next-line prettier/prettier
const StyledSpinner = styled(({ labeled, color, ...props }) => <Spinner {...props} />)`
  position: absolute;
  top: ${spaces.largeX + (48 - 32) / 2}px;
  right: ${spaces.smallX}px;

  &.text-custom {
    color: ${colors.gray.placeholder};

    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `};
  }

  ${({ labeled }) =>
    !labeled &&
    css`
      top: ${(48 - 32) / 2}px;
    `}
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledIconContainer = styled(forwardRef(({ labeled, ...props }, ref) => <div ref={ref} {...props} />))`
  display: flex;
  position: absolute;
  top: ${spaces.largeX + (48 - 24) / 2}px;
  right: ${spaces.small - 1}px;

  ${({ labeled }) =>
    !labeled &&
    css`
      top: ${(48 - 24) / 2}px;
    `}
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledFeedback = styled(forwardRef(({ color, ...props }, ref) => <Form.Control.Feedback ref={ref} {...props} />))`
  padding-left: ${spaces.smallest}px;

  font-family: 'Inter';
  font-size: ${fontSizes.regular}px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &.processing-feedback {
    color: ${colors.gray.placeholder};

    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `};
  }

  &.valid-feedback {
    color: ${colors.green.primary};
  }

  &.invalid-feedback {
    color: ${colors.red.primary};
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const BaseInput = forwardRef(({
      name,
      type,
      accept,
      hint,
      label,
      loading,
      labelSpace,
      required,
      noBottomSpace,
      textColor,
      placeholderColor,
      borderColor,
      backgroundColor,
      valid,
      invalid,
      feedback,
      value,
      onChange,
      ...restProps
    },
    ref
  ) => {
    const handleChangeFile = useCallback(
      (e) => {
        const { files } = e.target;
        onChange &&
          onChange({
            target: { name, value: files?.length ? files[0] : null },
          });
      },
      [name, onChange]
    );

    const overlayTriggerProps = useMemo(() => {
      const props = {
        placement: isMobile ? 'top' : 'right-start',
        delay: { show: 0, hide: 0 },
        trigger: ['hover', 'focus'],
        overlay: (
          <Tooltip id={`tooltip-${name}`} className="hintTooltip">
            {hint}
          </Tooltip>
        ),
      };

      if (!hint || isMobile) {
        props.show = false;
      }

      return props;
    }, [name, hint]);

    return (
      <OverlayTrigger {...overlayTriggerProps}>
        <StyledGroup
          labeled={Boolean(label || labelSpace)}
          noBottomSpace={noBottomSpace}
          className={invalid ? 'custom-control-invalid' : ''}
        >
          {label && (
            <StyledLabel invalid={invalid} textColor={textColor}>
              {label}
              {label && required && <span>&nbsp;*</span>}
            </StyledLabel>
          )}
          <StyledControl
            {...restProps}
            ref={ref}
            type={type}
            accept={accept}
            isValid={valid}
            isInvalid={invalid}
            processing={loading}
            textColor={textColor}
            placeholderColor={placeholderColor}
            borderColor={borderColor}
            backgroundColor={backgroundColor}
            value={type === 'file' ? undefined : value || ''}
            onChange={type === 'file' ? handleChangeFile : onChange}
            data-hj-allow
          />
          {loading && (
            <>
              <StyledSpinner
                animation="border"
                variant="custom"
                labeled={Boolean(label || labelSpace)}
                color={placeholderColor}
              />
              {feedback && (
                <StyledFeedback type="processing" color={textColor}>
                  {feedback}
                </StyledFeedback>
              )}
            </>
          )}
          {type === 'file' && value?.name && (
            <StyledFeedback type="processing" color={textColor}>
              {value.name}
            </StyledFeedback>
          )}
          {!loading && valid && (
            <>
              <StyledIconContainer labeled={Boolean(label || labelSpace)}>
                <Icon
                  alias={ICONS_ALIASES.tickSquare}
                  color={colors.green.primary}
                />
              </StyledIconContainer>
              {feedback && (
                <StyledFeedback type="valid">{feedback}</StyledFeedback>
              )}
            </>
          )}
          {!loading && invalid && (
            <>
              <StyledIconContainer labeled={Boolean(label || labelSpace)}>
                <Icon
                  alias={ICONS_ALIASES.closeSquare}
                  color={colors.red.primary}
                />
              </StyledIconContainer>
              {feedback && (
                <StyledFeedback type="invalid">{feedback}</StyledFeedback>
              )}
            </>
          )}
          {!loading && !valid && !invalid && type === 'file' && (
            <StyledIconContainer labeled={Boolean(label || labelSpace)}>
              <Icon
                alias={ICONS_ALIASES.link}
                color={placeholderColor || colors.gray.placeholder}
              />
            </StyledIconContainer>
          )}
        </StyledGroup>
      </OverlayTrigger>
    );
  }
);

BaseInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  accept: PropTypes.string,
  hint: PropTypes.node,
  label: PropTypes.string,
  labelSpace: PropTypes.bool,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  noBottomSpace: PropTypes.bool,
  textColor: PropTypes.string,
  placeholderColor: PropTypes.string,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  feedback: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default BaseInput;
