import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';

import BaseInput from '_components/atomic/controls/BaseInput';

const BaseField = ({
  type,
  name,
  control,
  rules = {},
  feedback,
  error,
  getPaymentProps,
  ...restProps
}) => {
  const [selectionTarget, setSelectionTarget] = useState(null);

  const handleTextSelect = (event) => {
    let elem = event.target;
    let selectionLength = elem.selectionEnd - elem.selectionStart;
    if (!selectionLength) {
      setSelectionTarget(null);
    } else {
      setSelectionTarget(elem.name);
    }
  };

  const getPaymentExtraProps = useCallback(() => {
    if (name === selectionTarget) {
      return { onKeyPress: () => {} };
    }

    return {};
  }, [selectionTarget, name]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
        fieldState: { error: cError },
        formState: { isSubmitted },
      }) => {
        if (getPaymentProps) {
          const { onChange, onBlur, value } = field;
          return (
            <BaseInput
              {...restProps}
              valid={isSubmitted && !cError}
              invalid={error ? Boolean(error) : isSubmitted && cError}
              feedback={
                error ? error?.message || feedback : cError?.message || feedback
              }
              {...getPaymentProps({ onChange, onBlur, value })}
              {...getPaymentExtraProps()}
              type={type}
              onSelect={handleTextSelect}
            />
          );
        }

        return (
          <BaseInput
            type={type}
            {...field}
            {...restProps}
            valid={isSubmitted && !cError}
            invalid={error ? Boolean(error) : isSubmitted && cError}
            feedback={
              error ? error?.message || feedback : cError?.message || feedback
            }
          />
        );
      }}
    />
  );
};

BaseField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  feedback: PropTypes.node,
  error: PropTypes.object,
  getPaymentProps: PropTypes.func,
};

export default BaseField;
