import { xVerifyApi } from '_api';

export const validateEmail = (options) => async (value) => {
  let validationResult = true;
  const { setProcessing, useEmailVerificationService } = options || {};

  if (useEmailVerificationService === false) {
    return validationResult;
  }

  if (setProcessing) {
    setProcessing(true);
  }

  let emailValue = value;

  if (emailValue.indexOf('+') > -1 && emailValue.indexOf('gmail.com') > -1) {
    emailValue =
      emailValue.substring(0, emailValue.indexOf('+')) +
      emailValue.substring(emailValue.indexOf('@'), emailValue.length);
  }

  const { status, reason } = await xVerifyApi.verifyEmail(emailValue);

  if (xVerifyApi.INVALID_EMAIL_STATUSES.includes(status)) {
    const defaultMessage = 'Email address is not valid';
    // eslint-disable-next-line max-len, prettier/prettier
    validationResult =
      xVerifyApi.VALIDATION_MESSAGE[reason] || defaultMessage;
  }

  if (setProcessing) {
    setProcessing(false);
  }

  return validationResult;
};

export const validatePhone = () => (value) => {
  const pureNumber = value.replace(/\D/g, '');

  if (pureNumber.length !== 10) {
    return 'Phone number should be equal 10 symbols';
  }

  return true;
};
